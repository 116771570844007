<template>
  <div class="confirmation-wrap">
    <NavBack/>
    <div class="sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div class="page-title has-text-weight-semibold has-text-centered" data-testid="page-title">
        {{membershipType === 'accredited' ? 'Accredited Member' : 'Become a member'}}
      </div>
      <div class="main has-background-white">
        <div class="section-title has-text-weight-semibold has-text-centered">
          {{membershipType === 'accredited' ? 'No need to wait to invest!' : 'Membership confirmation'}}
        </div>
        <div
          class="has-text-centered section-subtitle"
          v-if="membershipType === 'accredited'"
        >Become a member now so you don’t miss out — you will be able to invest higher than the ${{membershipDetails.addyOne.purchaseLimit}} investment cap 😮</div>
        <div class="step has-text-centered" v-else>Step 1/1</div>
        <div class="purple-box is-relative">
          <div class="balance-header has-text-weight-semibold">Your current wallet balance is</div>
          <div class="current-balance">
            <span class="symbol has-text-weight-semibold has-text-blue">$</span>
            <span class="balance has-text-blue" data-testid="wallet-balance">{{ walletBalance | truncate }}</span>
          </div>
          <div class="new-balance" v-if="dateFetched">
            <div>Your new wallet balance: <span class="balance has-text-weight-semibold">{{ newBalance | truncate | formatCurrency }}</span></div>
            <div v-if="isBalanceNegative" class="pt-2">
              Please click
              <span class="has-text-primary is-clickable has-text-weight-semibold" @click="toWalletPage">HERE</span>
              to fund your wallet first.
            </div>
          </div>
          <div class="confirmation-check">
            <b-checkbox v-model="checked" type="is-blue">
              I understand that by purchasing a membership, it will deduct its total cost from my addy wallet balance.
            </b-checkbox>
          </div>
        </div>
        <div class="summary">
          <div class="membership-type is-flex is-justify-content-space-between">
            <span
              :class="`name ${isAddyOneMembership ? '' : is-capitalized} has-text-weight-semibold`"
              data-testid="membership-type"
            >
              {{ membershipType }} Membership ({{currency}})
            </span>
            <span class="amount">{{ membershipCharge | formatCurrency }}</span>
          </div>
          <div class="tax is-flex is-justify-content-space-between">
            <span class="name is-capitalized has-text-weight-semibold">Tax</span>
            <span class="amount" data-testid="tax-amount">${{ taxAmount }}</span>
          </div>
          <div class="line"></div>
          <div class="total is-flex is-justify-content-space-between">
            <span class="total-name has-text-blue has-text-weight-semibold">Total ({{currency}})</span>
            <span class="total-amount has-text-blue" data-testid="total-amount">${{ membershipIncludingTax.toFixed(2) }}</span>
          </div>
        </div>
        <div class="switch-type" v-if="membershipType === 'accredited'">
          I want to become a
          <span
            class="has-text-primary has-text-weight-semibold is-clickable"
            data-testid="switch-type"
            @click="switchType"
          >addyOne member</span>
          instead.
        </div>
      </div>
      <div class="bottom-buttons is-flex is-justify-content-space-between is-align-items-center">
        <div
          class="has-text-primary button-cancel has-text-weight-semibold is-clickable"
          data-testid="cancel-btn"
          @click="cancelPurchase"
        >Cancel</div>
        <button
          :class="`button-buy button has-background-primary is-rounded is-medium-height has-text-weight-semibold ${buyButtonIsLoading ? 'is-loading' : ''}`"
          data-testid="buy-btn"
          @click="chargeMembership"
          :disabled="!checked || isBalanceNegative">Buy</button>
      </div>
    </div>
    <CancellationModal
      :showModal="showCancellationModal"
      @closeModal="closeCancellationModal"
      @doContinue="toMembershipSelection"
    ></CancellationModal>
  </div>
</template>
<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import NavBack from '@components/desktop/header-nav-back.vue'
import CancellationModal from './CancellationModal.vue'
import { getChargeDetails, createMembershipCharge } from '@api/membership'
import { getInvestorData } from '@api/signup'
import { truncate } from '@utils/common-methods/numberFormatter'
import { embeddedInIos, capitalizeFirstLetter, isAmericanUser } from '@utils/common-methods/common'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      walletBalance: 0,
      taxRate: 0,
      checked: false,
      dateFetched: false,
      buyButtonIsLoading: false,
      showCancellationModal: false,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    membershipDetails() {
      return JSON.parse(localStorage.getItem('membershipDetails'))
    },
    membershipType() {
      return this.$route.params.type
    },
    isAddyOneMembership() {
      return this.membershipType === 'addyOne'
    },
    membershipCharge() {
      return this.isAddyOneMembership ? 50 : 500
    },
    taxAmount() {
      return (this.membershipCharge * this.taxRate).toFixed(2)
    },
    membershipIncludingTax() {
      return this.membershipCharge + Number(this.taxAmount)
    },
    newBalance() {
      return this.walletBalance - this.membershipCharge - this.taxAmount
    },
    isBalanceNegative() {
      return this.newBalance < 0
    },
    returnUrl() {
      return decodeURIComponent(this.$route.query.returnUrl)
    },
    chargeType() {
      return this.membershipType === 'accredited' ? 'accredited_membership' : 'addyone_membership'
    },
    membershipChargeTypeToWords() {
      return `${this.isAddyOneMembership ? this.membershipType : capitalizeFirstLetter(this.membershipType)} Member`
    },
    isAmerican() {
      return isAmericanUser()
    },
    currency() {
      return this.isAmerican ? 'USD' : 'CAD'
    }
  },
  filters: {
    formatCurrency(value) {
      const number = Number(value)
      return number.toLocaleString('en-CA',
        { style: 'currency', currency: 'CAD' }
      )
    },
    truncate(num) {
      return truncate(num, 2)
    },
  },
  components: {
    MenuAndLogo,
    NavBack,
    CancellationModal,
  },
  created() {
    this.init()
  },
  mounted() {
    // authenticate the user with Mixpanel if this page is opened through a WKWebView
    if (embeddedInIos()) {
      getInvestorData().then((data) => {
        this.mixpanel.identify(data.email)
      })
    }
  },
  methods: {
    init() {
      getChargeDetails().then((data) => {
        if (!data.success) return
        this.walletBalance = data.data.walletBalance
        this.taxRate = data.data.taxRate
        this.dateFetched = true
      })
    },
    cancelPurchase() {
      this.mixpanel.track('8did7upp')
      if (this.membershipType === 'accredited') this.showCancellationModal = true
      else if (embeddedInIos()) window.webkit.messageHandlers.closeWebview.postMessage(true)
      else this.$router.push(this.returnUrl)
    },
    switchType() {
      this.$router.push(`/membership/confirmation/${this.membershipType === 'accredited' ? 'addyOne' : 'accredited'}?returnUrl=${encodeURIComponent(this.returnUrl)}`)
    },
    chargeMembership() {
      if (!this.checked || this.isBalanceNegative) return

      this.buyButtonIsLoading = true
      createMembershipCharge({
        charge_amount: this.membershipCharge,
        tax_amount: Number(this.taxAmount),
        charge_type: this.chargeType,
      }).then((result) => {
        if (!result.success) return
        this.mixpanel.track('kawktns9', {
          platform: embeddedInIos() ? 'native' : 'mobile web'
        })
        const investor = JSON.parse(localStorage.getItem('investor'))
        investor.membershipType = this.membershipChargeTypeToWords
        localStorage.setItem('investor', JSON.stringify(investor))
        this.$router.push(`/membership/success?returnUrl=${encodeURIComponent(this.returnUrl)}&type=${this.membershipType}`)
      })
    },
    closeCancellationModal() {
      this.showCancellationModal = false
    },
    toMembershipSelection() {
      this.$router.replace(`/membership/options?returnUrl=${encodeURIComponent(this.returnUrl)}`)
    },
    toWalletPage() {
      this.$router.push('/wallet')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';
.confirmation-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  padding-bottom: 80px;
  .has-padding {
    padding: 0 25px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      min-height: 490px;
      border-radius: 12px;
      box-shadow: 0px 30px 50px rgba(215, 145, 51, 0.11);
      margin-bottom: 30px;
      padding: 0 14px 18px;
      .section-title {
        font-size: 16px;
        padding-top: 22px;
      }
      .section-subtitle {
        font-size: 16px;
        line-height: 19px;
        padding: 8px 4px 24px;
      }
      .step {
        font-size: 14px;
        color: #9185AE;
        padding: 8px 0 12px;
      }
      .purple-box {
        background: #F0EEF8;
        border-radius: 6px;
        padding: 16px 17px;
        height: 245px;
        .balance-header {
          font-size: 16px;
          padding-bottom: 12px;
        }
        .current-balance {
          .symbol {
            font-size: 20px;
            vertical-align: top;
            line-height: 29px;
          }
          .balance {
            padding-left: 6px;
            font-size: 40px;
          }
        }
        .new-balance {
          padding-top: 10px;
          > div {
            color: #9185AE;
            font-size: 14px;
            line-height: 17px;
            .balance {
              color: #9185AE;
            }
          }
        }
        .confirmation-check {
          position: absolute;
          top: 164px;
          left: 0;
          width: 100%;
          padding-left: 17px;
        }
      }
      .summary {
        font-size: 16px;
        margin-top: 22px;
        .membership-type {
          margin-bottom: 12px;
        }
        .tax {
          margin-bottom: 16px;
        }
        .line {
          height: 1px;
          background-color: #9185AE;
        }
        .total {
          margin-top: 12px;
          font-size: 24px;
        }
      }
      .switch-type {
        padding-top: 14px;
        color: #939598;
        font-size: 16px;
      }
    }
    .bottom-buttons {
      margin-top: 28px;
      .button-cancel {
        font-size: 16px;
        margin-left: 25px;
      }
      .button-buy {
        width: 94px;
      }
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .confirmation-wrap {
    padding-top: 19px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
  }
}
</style>
<style scoped>
.checkbox >>> .check {
  width: 24px!important;
  height: 24px!important;
  border-color: #4A26AA!important;
}
.checkbox >>> .control-label {
  font-size: 16px;
  line-height: 19px;
  padding-left: 15px;
}
</style>

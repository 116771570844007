<template>
  <div :class="`modal px-5 ${isActive ? 'is-active' : ''}`">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-content has-background-white">
      <img src="@assets/icons/close.svg" alt="close" class="icon-close" @click="closeModal">
      <div class="modal-header has-text-centered">Cancel application?</div>
      <div class="modal-text">
        Are you sure you want to cancel? You will be returned to the membership selection page.
      </div>
      <div class="is-flex is-justify-content-space-between buttons">
        <b-button
          type="button-no"
          class="has-text-weight-bold"
          data-testid="no-btn"
          @click="closeModal"
        >No</b-button>
        <b-button
          type="is-blue button-yes"
          :loading="isLoading"
          class="has-text-weight-bold"
          data-testid="yes-btn"
          @click="doContinue"
        >Yes</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { cancelAccreditedApplication } from '@api/membership'
export default {
  data() {
    return {
      isActive: false,
      isLoading: false,
    }
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    cancelThroughEndpoint: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.isActive = this.showModal
  },
  watch: {
    showModal(newValue) {
      this.isActive = newValue
    }
  },
  methods: {
    closeModal() {
      this.isActive = false
      this.$emit('closeModal')
    },
    doContinue() {
      if (!this.cancelThroughEndpoint) return this.$emit('doContinue')
      this.isLoading = true
      cancelAccreditedApplication().then((result) => {
        if (!result.success) return
        this.$emit('doContinue')
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';
.modal {
  z-index: 100;
  .modal-content {
    max-width: $mobile-wrapper-width;
    position: relative;
    border-radius: 12px;
    padding: 48px 26px 24px;
    .icon-close {
      width: 26px;
      position: absolute;
      left: 16px;
      top: 14px;
    }
    .modal-header {
      font-weight: 600;
      font-size: 28px;
      padding-bottom: 20px;
    }
    .modal-text {
      font-size: 16px;
      padding: 0 0 30px;
      line-height: 20px;
    }
    .buttons {
      padding: 0 6px;
      .button-no {
        width: calc(50% - 6px);
        font-size: 16px;
        height: 43px;
        border-radius: 12px;
        border-color: #4A26AA;
      }
      .button-yes {
        width: calc(50% - 6px);
        font-size: 16px;
        height: 43px;
        border-radius: 12px;
      }
    }
  }
}
</style>
<style scoped>
.button-no >>> span {
  color: #4A26AA;
}
</style>

/**
 * @param {Number} number          - required
 * @param {Number} decimalPlace    - not required, 2 by default
 * @param {String} decimal         - not required, '.' by default
 * @param {String} delimiter       - not required, ',' by default
 * @return {String}
 */
export const formatter = (number, decimalPlace = 2, decimal = '.', delimiter = ',') => {
  const minus = number < 0 ? '-' : ''
  const myString = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decimalPlace)))
  let length = myString.length
  length = length > 3 ? length % 3 : 0
  return minus + (length ? myString.substr(0, length) + delimiter : '') + myString.substr(length).replace(/(\d{3})(?=\d)/g, '$1' + delimiter) + (decimalPlace ? decimal + Math.abs(number - myString).toFixed(decimalPlace).slice(2) : '')
}

/**
 * @param {Number} num        - required
 * @param {Number} decimal    - not required, 2 by default
 * @return {String}
 */
/* eslint-disable no-useless-escape */
export const truncate = (num, decimal = 2) => {
  const regExp = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimal || -1) + '})?')
  const truncated = num.toString().match(regExp)[0]
  return Number(truncated).toFixed(decimal)
}

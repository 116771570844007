import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Get Membership Charge Details
 * @return {Promise}
 */
export const getChargeDetails = () => {
  return axios.get(getImbyRoute('/api/v1/membership_charges/member_charge_details'))
}

/**
 * Create Membership Charge
 * @param {Object} payload charge_amount, tax_amount, charge_type
 * @return {Promise}
 */
export const createMembershipCharge = (payload) => {
  return axios.post(getImbyRoute('/api/v1/membership_charges'), { membership_charge: payload })
}

/**
 * Cancel accredited membership application
 * @return {Promise}
 */
export const cancelAccreditedApplication = () => {
  return axios.post(getImbyRoute('/api/v1/membership_charges/accredited/cancel'))
}

/**
 * submit accredited paragraph and spousal status
 * @param {Object} payload
 * @return {Promise}
 */
export const submitAccreditedParagraph = (payload) => {
  return axios.put(getImbyRoute('/api/v1/membership_charges/accredited/declare_accredited_status'), payload)
}

/**
 * Initialize Stripe order
 * @param {String} chargeType annual_membership/believer_membership/accredited_membership
 * @return {Promise}
 */
export const createStripeOrder = (chargeType) => {
  return axios.post(getImbyRoute('/api/v1/membership_charges/stripe_order_create'), { membership_charge: { charge_type: chargeType } })
}

/**
 * Submit Stripe order
 * @param {Object} payload
 * @return {Promise}
 */
export const submitStripeOrder = (payload) => {
  return axios.post(getImbyRoute('/api/v1/membership_charges/stripe_order_submit'), { membership_charge: payload })
}
